.update-project-container {
  /* margin: 20px; */
  width: 100%;
}

.project-state-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.steps-container {
  margin-bottom: 20px;
}

.tabs-container {
  margin-left: 20px;
}

.form-container {
  width: 100%;
  max-width: 95%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin: 0 auto;
}

.form-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-container .ant-form-item {
  margin-bottom: 16px;
}

.form-container .ant-btn {
  width: 100%;
}