.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .table-container {
  margin: 20px;
  width: 100%;
} */

.search-bar {
  margin: 20px;
  width: 75%;
}


.project-details {
  margin-top: 20px;
  width: 100%;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between; */
}

.ant-descriptions {
  margin-bottom: 25px;
}

.project-details {
  margin-bottom: 20px;
}

.project-actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}