.table-container {
  margin: 20px;
  width: 95%;
}

.ant-table-thead>tr>th {
  background-color: #f0f0f0;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-tbody>tr:hover>td {
  background-color: #e6f7ff;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: #bae7ff;
}

.ant-pagination {
  margin-top: 20px;
}

.ant-pagination-item-active a {
  color: #1890ff;
}

.ant-pagination-item a:hover {
  border-color: #1890ff;
}

.ant-pagination-disabled a {
  color: #ccc;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.create-button {
  margin-right: auto;
}

.edit-button {
  margin-left: auto;
}