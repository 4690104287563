.update-ghg-methodology-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.form-container {
  width: 500px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.form-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.form-container .ant-form-item {
  margin-bottom: 16px;
}

.form-container .ant-btn {
  width: 100%;
}